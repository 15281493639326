// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info {
    margin-top: 8%;
    /* margin-right: 80%; */
}

`, "",{"version":3,"sources":["webpack://./src/filedropper.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,uBAAuB;AAC3B","sourcesContent":[".info {\n    margin-top: 8%;\n    /* margin-right: 80%; */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
