// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* uploadLoader.css */ 
.uploadloader{
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #673AB7;
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    mask: var(--_m);
    mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}
`, "",{"version":3,"sources":["webpack://./src/component/helpers/uploadLoader.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB;;2CAEuC;IAE/B,eAAe;IAEf,wBAAwB;IAChC,gCAAgC;EAClC;EACA,eAAe,GAAG,wBAAwB,CAAC","sourcesContent":["/* uploadLoader.css */ \n.uploadloader{\n    width: 50px;\n    padding: 8px;\n    aspect-ratio: 1;\n    border-radius: 50%;\n    background: #673AB7;\n    --_m: \n      conic-gradient(#0000 10%,#000),\n      linear-gradient(#000 0 0) content-box;\n    -webkit-mask: var(--_m);\n            mask: var(--_m);\n    -webkit-mask-composite: source-out;\n            mask-composite: subtract;\n    animation: l3 1s infinite linear;\n  }\n  @keyframes l3 {to{transform: rotate(1turn)}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
