import React from 'react'

export const TermsAndConditionText = () => {
  return (
    <div className='terms-conditions-text'>
        <p>
        Thank you for using Finsight Analytics - Data Geniee!<br />
        These Terms of Use apply to your use of Data geniee and Finsight Analytics - Data Geniee’s other services for individuals, along with any associated software applications and websites (all together, “Services”).<br />
        These Terms form an agreement between you and Finsight Analytics - Data Geniee, a company, and they include our Service Terms and important provisions for resolving disputes through arbitration. By using our Services, you agree to these Terms.<br />
        Our Business Terms govern the use of Data geniee, our APIs, and our other services for businesses and developers.<br />
        Our Privacy Policy explains how we collect and use personal information. Although it does not form part of these Terms, it is an important document that you should read.<br />
        <br />
        Who we are<br />
        Finsight Analytics - Data Geniee is an AI research and deployment company. Our mission is to ensure that artificial general intelligence benefits all of humanity.<br />
        <br />
        Registration and Access<br />
        Minimum Age. You must be at least 13 years old or the minimum age required in your country to consent to use the Services. If you are under 18, you must have your parent or legal guardian’s permission to use the Services.<br />
        Registration. You must provide accurate and complete information to register for an account to use our Services. You may not share your account credentials or make your account available to anyone else and are responsible for all activities that occur under your account. If you create an account or use the Services on behalf of another person or entity, you must have the authority to accept these Terms on their behalf.<br />
        <br />
        Using Our Services<br />
        What You Can Do. Subject to your compliance with these Terms, you may access and use our Services. In using our Services, you must comply with all applicable laws and our Sharing & Publication Policy, Usage Policies, and other guidelines we make available.<br />
        What You Cannot Do. You may not use our Services for any illegal, harmful, or abusive activity. For example, you may not:<br />
        - Use our Services in a way that infringes, misappropriates, or violates anyone’s rights.<br />
        - Modify, copy, lease, sell, or distribute any of our Services.<br />
        - Attempt to or assist anyone in reverse engineering, decompiling, or discovering the source code or underlying components of our Services, including our models, algorithms, or systems (except to the extent this restriction is prohibited by applicable law).<br />
        - Automatically or programmatically extract data or Output (defined below).<br />
        - Represent that Output was human-generated when it was not.<br />
        - Interfere with or disrupt our Services, including circumventing any rate limits or restrictions or bypassing any protective measures or safety mitigations we put on our Services.<br />
        - Use Output to develop models that compete with Finsight Analytics - Data Geniee.<br />
        Software. Our Services may allow you to download software, such as mobile applications, which may update automatically to ensure you’re using the latest version. Our software may include open-source software that is governed by its own licenses that we've made available to you.<br />
        Corporate Domains. If you create an account using an email address owned by an organization (for example, your employer), that account may be added to the organization's business account with us, in which case we will provide notice to you so that you can help facilitate the transfer of your account (unless your organization has already provided notice to you that it may monitor and control your account). Once your account is transferred, the organization’s administrator will be able to control your account, including being able to access Content (defined below) and restrict or remove your access to the account.<br />
      
        <br />
        Third Party Services. Our services may include third-party software, products, or services (“Third Party Services”), and some parts of our Services, like our browse feature, may include output from those services (“Third Party Output”).<br />
    Third Party Services and Third Party Output are subject to their own terms, and we are not responsible for them.<br />
    Feedback. We appreciate your feedback, and you agree that we may use it without restriction or compensation to you.<br />
    <br />
    Content<br />
    Your Content. You may provide input to the Services (“Input”), and receive output from the Services based on the Input (“Output”). Input and Output are collectively “Content.” You are responsible for Content, including ensuring that it does not violate any applicable law or these Terms. You represent and warrant that you have all rights, licenses, and permissions needed to provide Input to our Services.<br />
    Ownership of Content. As between you and Finsight Analytics - Data Geniee, and to the extent permitted by applicable law, you (a) retain your ownership rights in Input and (b) own the Output. We hereby assign to you all our right, title, and interest, if any, in and to Output.<br />
    Similarity of Content. Due to the nature of our Services and artificial intelligence generally, output may not be unique, and other users may receive similar output from our Services. Our assignment above does not extend to other users’ output or any Third Party Output.<br />
    Our Use of Content. We may use Content to provide, maintain, develop, and improve our Services, comply with applicable law, enforce our terms and policies, and keep our Services safe.<br />
    Accuracy. Artificial intelligence and machine learning are rapidly evolving fields of study. We are constantly working to improve our Services to make them more accurate, reliable, safe, and beneficial. Given the probabilistic nature of machine learning, use of our Services may, in some situations, result in Output that does not accurately reflect real people, places, or facts.<br />
    When you use our Services, you understand and agree:<br />
    Output may not always be accurate. You should not rely on Output from our Services as a sole source of truth or factual information, or as a substitute for professional advice.<br />
    You must evaluate Output for accuracy and appropriateness for your use case, including using human review as appropriate, before using or sharing Output from the Services.<br />
    You must not use any Output relating to a person for any purpose that could have a legal or material impact on that person, such as making credit, educational, employment, housing, insurance, legal, medical, or other important decisions about them.<br />
    Our Services may provide incomplete, incorrect, or offensive Output that does not represent Finsight Analytics - Data Geniee’s views. If Output references any third-party products or services, it doesn’t mean the third party endorses or is affiliated with Finsight Analytics - Data Geniee.<br />
    Paid Accounts  <br/>
Billing. If you purchase any Services, you will provide complete and accurate billing information, including a valid payment method. For paid subscriptions, we will automatically charge your payment method on each agreed-upon periodic renewal until you cancel. You’re responsible for all applicable taxes, and we’ll charge tax when required. If your payment cannot be completed, we may downgrade your account or suspend your access to our Services until payment is received. <br />
Cancellation. You can cancel your paid subscription at any time. Payments are non-refundable, except where required by law. These Terms do not override any mandatory local laws regarding your cancellation rights. <br />
Changes. We may change our prices from time to time. If we increase our subscription prices, we will give you at least 7 days’ notice and any price increase will take effect on your next renewal so that you can cancel if you do not agree to the price increase. <br />
Termination and Suspension<br />
Termination. You are free to stop using our Services at any time. We reserve the right to suspend or terminate your access to our Services or delete your account if we determine: <br />
You breached these Terms <br />
We must do so to comply with the law. <br />
Your use of our Services could cause risk or harm to Finsight Analytics - Data Geniee, our users, or anyone else. <br />
We also may terminate your account if it has been inactive for over a year and you do not have a paid account. If we do, we will provide you with advance notice. <br />
Appeals. If you believe we have suspended or terminated your account in error, you can file an appeal with us by contacting our Support team email given below. <br />
Discontinuation of Services<br />
We may decide to discontinue our Services, but if we do, we will give you advance notice and a refund for any prepaid, unused Services. <br />
Disclaimer of Warranties<br />
OUR SERVICES ARE PROVIDED “AS IS.” EXCEPT TO THE EXTENT PROHIBITED BY LAW, WE AND OUR AFFILIATES AND LICENSORS MAKE NO WARRANTIES (EXPRESS, IMPLIED, STATUTORY OR OTHERWISE) WITH RESPECT TO THE SERVICES, AND DISCLAIM ALL WARRANTIES INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, NON-INFRINGEMENT, AND QUIET ENJOYMENT, AND ANY WARRANTIES ARISING OUT OF ANY COURSE OF DEALING OR TRADE USAGE. WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, ACCURATE OR ERROR FREE, OR THAT ANY CONTENT WILL BE SECURE OR NOT LOST OR ALTERED. <br />
YOU ACCEPT AND AGREE THAT ANY USE OF OUTPUTS FROM OUR SERVICE IS AT YOUR SOLE RISK AND YOU WILL NOT RELY ON OUTPUT AS A SOLE SOURCE OF TRUTH OR FACTUAL INFORMATION, OR AS A SUBSTITUTE FOR PROFESSIONAL ADVICE. <br />
Limitation of Liability<br />
NEITHER WE NOR ANY OF OUR AFFILIATES OR LICENSORS WILL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, OR DATA OR OTHER LOSSES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE LIABILITY UNDER THESE TERMS WILL NOT EXCEED THE GREATER OF THE AMOUNT YOU PAID FOR THE SERVICE THAT GAVE RISE TO THE CLAIM DURING THE 12 MONTHS BEFORE THE LIABILITY AROSE OR ONE HUNDRED DOLLARS ($100). THE LIMITATIONS IN THIS SECTION APPLY ONLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. <br />
Some countries and states do not allow the disclaimer of certain warranties or the limitation of certain damages, so some or all of the terms above may not apply to you, and you may have additional rights. In that case, these Terms only limit our responsibilities to the maximum extent permissible in your country of residence. <br />
FINSIGHT ANALYTICS - DATA GENIEE’S AFFILIATES, SUPPLIERS, LICENSORS, AND DISTRIBUTORS ARE INTENDED THIRD PARTY BENEFICIARIES OF THIS SECTION. <br />
Indemnity<br />
If you are a business or organization, to the extent permitted by law, you will indemnify and hold harmless us, our affiliates, and our personnel, from and against any costs, losses, liabilities, and expenses (including attorneys’ fees) from third party claims arising out of or relating to your use of the Services and Content or any violation of these Terms. <br />
Dispute Resolution<br />
YOU AND FINSIGHT ANALYTICS - DATA GENIEE AGREE TO THE FOLLOWING MANDATORY ARBITRATION AND CLASS ACTION WAIVER PROVISIONS: <br />
MANDATORY ARBITRATION. You and Finsight Analytics - Data Geniee agree to resolve any claims arising out of or relating to these Terms or our Services, regardless of when the claim arose, even if it was before these Terms existed (a “Dispute”), through final and binding arbitration. You may opt out of arbitration within 30 days of account creation or of any updates to these arbitration terms within 30 days after the update has taken effect by writing to us below email. If you opt out of an update, the last set of agreed upon arbitration terms will apply. <br />
Informal Dispute Resolution. We would like to understand and try to address your concerns prior to formal legal action. Before either of us files a claim against the other, we both agree to try to resolve the Dispute informally. You agree to do so by sending us notice through this email given below. We will do so by sending you notice to the email address associated with your account. If we are unable to resolve a Dispute within 60 days, either of us has the right to initiate arbitration. We also both agree to attend an individual settlement conference if either party requests one during this time. Any statute of limitations will be tolled during this informal resolution process. <br />
Arbitration Forum. If we are unable to resolve the Dispute, either of us may commence arbitration with National Arbitration and Mediation (“NAM”) under its Comprehensive Dispute Resolution Rules and Procedures and/or Supplemental Rules for Mass Finsight Analytics - Data Geniee will not seek attorneys’ fees and costs in arbitration unless the arbitrator determines that your claim is frivolous. The activities described in these Terms involve interstate commerce and the Federal Arbitration Act will govern the interpretation and enforcement of these arbitration terms and any arbitration. <br />
Arbitration Procedures. The arbitration will be conducted by videoconference if possible, but if the arbitrator determines a hearing should be conducted in person, the location will be mutually agreed upon, in the county where you reside, or as determined by the arbitrator, unless the batch arbitration process applies. The arbitration will be conducted by a sole arbitrator. The arbitrator will be either a retired judge or an attorney licensed to practice law in the state of India. The arbitrator will have exclusive authority to resolve any Dispute, except the state or federal courts of San Francisco, India have the authority to determine any Dispute about enforceability, validity of the class action waiver, or requests for public injunctive relief, as set out below. Any settlement offer amounts will not be disclosed to the arbitrator by either party until after the arbitrator determines the final award, if any. The arbitrator has the authority to grant motions dispositive of all or part of any Dispute. <br />
Exceptions. This section does not require informal dispute resolution or arbitration of the following claims: (i) individual claims brought in small claims court; and (ii) injunctive or other equitable relief to stop unauthorized use or abuse of the Services or intellectual property infringement or misappropriation. <br />
CLASS AND JURY TRIAL WAIVERS. You and Finsight Analytics - Data Geniee agree that Disputes must be brought on an individual basis only, and may not be brought as a plaintiff or class member in any purported class, consolidated, or representative proceeding. Class arbitrations, class actions, and representative actions are prohibited. Only individual relief is available. The parties agree to sever and litigate in court any request for public injunctive relief after completing arbitration for the underlying claim and all other claims. This does not prevent either party from participating in a class-wide settlement. You and Finsight Analytics - Data Geniee knowingly and irrevocably waive any right to trial by jury in any action, proceeding, or counterclaim. <br />
Batch Arbitration. If 25 or more claimants represented by the same or similar counsel file demands for arbitration raising substantially similar Disputes within 90 days of each other, then you and Finsight Analytics - Data Geniee agree that NAM will administer them in batches of up to 50 claimants each (“Batch”), unless there are less than 50 claimants in total or after batching, which will comprise a single Batch. NAM will administer each Batch as a single consolidated arbitration with one arbitrator, one set of arbitration fees, and one hearing held by videoconference or in a location decided by the arbitrator for each Batch. If any part of this section is found to be invalid or unenforceable as to a particular claimant or Batch, it will be severed and arbitrated in individual proceedings.  <br />
Severability. If any part of these arbitration terms is found to be illegal or unenforceable, the remainder will remain in effect, except that if a finding of partial illegality or unenforceability would allow class arbitration, class action, or representative action, this entire dispute resolution section will be unenforceable in its entirety. <br />
Copyright Complaints<br />
If you believe that your intellectual property rights have been infringed, please send notice to the address below. We may delete or disable content that we believe violates these Terms or is alleged to be infringing and will terminate accounts of repeat infringers where appropriate. <br />
Finsight Analytics L.L.P. <br />
support@finsightanalytics.org<br />
Written claims concerning copyright infringement must include the following information: <br />
A physical or electronic signature of the person authorized to act on behalf of the owner of the copyright interest<br />
A description of the copyrighted work that you claim has been infringed upon<br />
A description of where the allegedly infringing material is located on our site so we can find it<br />
Your address, telephone number, and e-mail address<br />
A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law<br />
A statement by you that the above information in your notice is accurate and, under penalty of perjury, that you are the copyright owner or authorized to act on the copyright owner’s behalf<br />
General Terms<br />
Assignment. You may not assign or transfer any rights or obligations under these Terms and any attempt to do so will be void. We may assign our rights or obligations under these Terms to any affiliate, subsidiary, or successor in interest of any business associated with our Services. <br />
Changes to These Terms or Our Services. We are continuously working to develop and improve our Services. We may update these Terms or our Services accordingly from time to time. For example, we may make changes to these Terms or the Services due to: <br />
Changes to the law or regulatory requirements. <br />
Security or safety reasons. <br />
Circumstances beyond our reasonable control. <br />
Changes we make in the usual course of developing our Services. <br />
To adapt to new technologies. <br />
We will give you at least 30 days advance notice of changes to these Terms that materially adversely impact you either via email or an in-product notification. All other changes will be effective as soon as we post them to our website. If you do not agree to the changes, you must stop using our Services. <br />
Delay in Enforcing These Terms. Our failure to enforce a provision is not a waiver of our right to do so later. Except as provided in the dispute resolution section above, if any portion of these Terms is determined to be invalid or unenforceable, that portion will be enforced to the maximum extent permissible and it will not affect the enforceability of any other terms. <br />
Trade Controls. You must comply with all applicable trade laws, including sanctions and export control laws. Our Services may not be used in or for the benefit of, or exported or re-exported to (a) any U.S. embargoed country or territory or (b) any individual or entity with whom dealings are prohibited or restricted under applicable trade laws. Our Services may not be used for any end use prohibited by applicable trade laws, and your Input may not include material or information that requires a government license for release or export. <br />
Entire Agreement. These Terms contain the entire agreement between you and Finsight Analytics - Data Geniee regarding the Services and, other than any Service-specific terms, supersedes any prior or contemporaneous agreements between you and Finsight Analytics - Data Geniee. <br />
Governing Law. Indian law will govern these Terms except for its conflicts of laws principles. Except as provided in the dispute resolution section above, all claims arising out of or relating to these Terms will be brought exclusively in the federal or state courts of San Francisco, India. <br />

We at Finsight Anlaytics (together with our affiliates, “Finsight Anlaytics”, “we”, “our” or “us”) respect your privacy and are strongly committed to keeping secure any information we obtain from you or about you. This Privacy Policy describes our practices with respect to Personal Information we collect from or about you when you use our website, applications, and services (collectively, “Services”). This Privacy Policy does not apply to content that we process on behalf of customers of our business offerings, such as our API. Our use of that data is governed by our customer agreements covering access to and use of those offerings. <br />
For information about how we collect and use training information to develop our language models that power Data geniee and other Services, and your choices with respect to that information, please see this help center article. <br />
1. Personal information we collect<br />
We collect personal information relating to you (“Personal Information”) as follows: <br />
Personal Information You Provide: We collect Personal Information if you create an account to use our Services or communicate with us as follows: <br />
Account Information: When you create an account with us, we will collect information associated with your account, including your name, contact information, account credentials, payment card information, and transaction history, (collectively, “Account Information”). <br />
User Content: When you use our Services, we collect Personal Information that is included in the input, file uploads, or feedback that you provide to our Services (“Content”). <br />
Communication Information: If you communicate with us, we collect your name, contact information, and the contents of any messages you send (“Communication Information”). <br />
Social Media Information: We have pages on social media sites like Instagram, Facebook, Medium, Twitter, YouTube and LinkedIn. When you interact with our social media pages, we will collect Personal Information that you elect to provide to us, such as your contact details (collectively, “Social Information”). In addition, the companies that host our social media pages may provide us with aggregate information and analytics about our social media activity. <br />
Other Information You Provide: We collect other information that you may provide to us, such as when you participate in our events or surveys or provide us with information to establish your identity (collectively, “Other Information You Provide” <br />
Personal Information We Receive Automatically From Your Use of the Services: When you visit, use, or interact with the Services, we receive the following information about your visit, use, or interactions (“Technical Information”): <br />
Log Data: Information that your browser or device automatically sends when you use our Services. Log data includes your Internet Protocol address, browser type and settings, the date and time of your request, and how you interact with our Services. <br />
Usage Data: We may automatically collect information about your use of the Services, such as the types of content that you view or engage with, the features you use and the actions you take, as well as your time zone, country, the dates and times of access, user agent and version, type of computer or mobile device, and your computer connection. <br />
Device Information: Includes name of the device, operating system, device identifiers, and browser you are using. Information collected may depend on the type of device you use and its settings. <br />
Cookies: We use cookies to operate and administer our Services, and improve your experience. A “cookie” is a piece of information sent to your browser by a website you visit. You can set your browser to accept all cookies, to reject all cookies, or to notify you whenever a cookie is offered so that you can decide each time whether to accept it. <br />However, refusing a cookie may in some cases preclude you from using, or negatively affect the display or function of, a website or certain areas or features of a website. For more details on cookies, please visit All About Cookies. <br />
Analytics: We may use a variety of online analytics products that use cookies to help us analyze how users use our Services and enhance your experience when you use the Services. <br />
2. How we use personal information<br />
We may use Personal Information for the following purposes: <br />
To provide, administer, maintain and/or analyze the Services; <br />
To improve our Services and conduct research; <br />
To communicate with you; including to send you information about our Services and events; <br />
To develop new programs and services; <br />
To prevent fraud, criminal activity, or misuses of our Services, and to protect the security of our IT systems, architecture, and networks; <br />
To carry out business transfers; and<br />
To comply with legal obligations and legal process and to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or other third parties. <br />
Aggregated or De-Identified Information. We may aggregate or de-identify Personal Information so that it may no longer be used to identify you and use such information to analyze the effectiveness of our Services, to improve and add features to our Services, to conduct research and for other similar purposes. In addition, from time to time, we may analyze the general behavior and characteristics of users of our Services and share aggregated information like general user statistics with third parties, publish such aggregated information or make such aggregated information generally available. We may collect aggregated information through the Services, through cookies, and through other means described in this Privacy Policy. We will maintain and use de-identified information in anonymous or de-identified form and we will not attempt to reidentify the information, unless required by law. <br />
As noted above, we may use Content you provide us to improve our Services, for example to train the models that power Data geniee. Read our instructions on how you can opt out of our use of your Content to train our models. <br />
3. Disclosure of personal information<br />
In certain circumstances we may provide your Personal Information to third parties without further notice to you, unless required by the law: <br />
Vendors and Service Providers: To assist us in meeting business operations needs and to perform certain services and functions, we may provide Personal Information to vendors and service providers, including providers of hosting services, customer service vendors, cloud services, email communication software, web analytics services, and other information technology providers, among others. Pursuant to our instructions, these parties will access, process, or store Personal Information only in the course of performing their duties to us. <br />
Business Transfers: If we are involved in strategic transactions, reorganization, bankruptcy, receivership, or transition of service to another provider (collectively, a “Transaction”), your Personal Information and other information may be disclosed in the diligence process with counterparties and others assisting with the Transaction and transferred to a successor or affiliate as part of that Transaction along with other assets. <br />
Legal Requirements: We may share your Personal Information, including information about your interaction with our Services, with government authorities, industry peers, or other third parties (i) if required to do so by law or in the good faith belief that such action is necessary to comply with a legal obligation, (ii) to protect and defend our rights or property, (iii) if we determine, in our sole discretion, that there is a violation of our terms, policies, or the law; (iv) to detect or prevent fraud or other illegal activity; (v) to protect the safety, security, and integrity of our products, employees, or users, or the public, or (vi) to protect against legal liability. <br />
Affiliates: We may disclose Personal Information to our affiliates, meaning an entity that controls, is controlled by, or is under common control with Finsight Anlaytics. Our affiliates may use the Personal Information we share in a manner consistent with this Privacy Policy. <br />
Business Account Administrators: When you join a Data geniee Enterprise or business account, the administrators of that account may access and control your Finsight Anlaytics account. In addition, if you create an account using an email address belonging to your employer or another organization, we may share the fact that you have an Finsight Anlaytics account and certain account information, such as your email address, with your employer or organization to, for example, enable you to be added to their business account. <br />
Other Users and Third Parties You Share Information With: Certain features allow you to display or share information with other users or third parties. For example, you may share Data geniee conversations with other users via shared links or send information to third-party applications via custom actions for GPTs. Be sure you trust any user or third party with whom you share information. <br />
4. Your rights<br />
Depending on location, individuals may have certain statutory rights in relation to their Personal Information. For example, you may have the right to: <br />
Access your Personal Information and information relating to how it is processed. <br />
Delete your Personal Information from our records. <br />
Rectify or update your Personal Information. <br />
Transfer your Personal Information to a third party (right to data portability). <br />
Restrict how we process your Personal Information. <br />
Withdraw your consent—where we rely on consent as the legal basis for processing at any time. <br />
Object to how we process your Personal Information. <br />
Lodge a complaint with your local data protection authority. <br />
You can exercise some of these rights through your Finsight Anlaytics account. If you are unable to exercise your rights through your account, please submit your request through support@finsightanalytics.org  . <br />
A note about accuracy: Services like Data geniee generate responses by reading a user’s request and, in response, predicting the words most likely to appear next. In some cases, the words most likely to appear next may not be the most factually accurate. For this reason, you should not rely on the factual accuracy of output from our models. If you notice that Data geniee output contains factually inaccurate information about you and you would like us to correct the inaccuracy, you may submit a correction request through support@finsightanalytics.org. Given the technical complexity of how our models work, we may not be able to correct the inaccuracy in every instance. <br />
For information on how to exercise your rights with respect to data we have collected from the internet to train our models, please see this help center article. <br />
5. Additional U.S. state disclosures<br />
The following table provides additional information about the categories of Personal Information we collect and how we disclose that information. You can read more about the Personal Information we collect in “Personal information we collect” above, how we use Personal Information in “How we use personal information” above, and how we retain Personal Information in “Security and Retention” below. <br />
Category of Personal Information	Disclosure of Personal Information<br />
Identifiers, such as your name, contact details, IP address, and other device identifiers,We may disclose this information to our affiliates, vendors and service providers to process in accordance with our instructions; to law enforcement and other third parties for the legal reasons described above; to parties involved in Transactions; to corporate administrators of enterprise or team accounts; and to other users and third parties you choose to share it with. <br />
Commercial Information, such as your transaction history.We may disclose this information to our affiliates, vendors and service providers to process in accordance with our instructions; to law enforcement and other third parties for the legal reasons described above; to parties involved in Transactions; and to corporate administrators of enterprise or team accounts. <br />
Network Activity Information, such as Content and how you interact with our Services.We may disclose this information to our affiliates, vendors and service providers to process in accordance with our instructions; to law enforcement and other third parties for the legal reasons described above; to parties involved in Transactions; and to other users and third parties you choose to share it with. <br />
Geolocation Data	We may disclose this information to our affiliates, vendors and service providers to process in accordance with our instructions; to law enforcement and other third parties for the legal reasons described above; and to parties involved in Transactions. <br />
Your account login credentials and payment card information (Sensitive Personal Information)	We disclose this information to our affiliates, vendors and service providers, law enforcement, and parties involved in Transactions. <br />
To the extent provided for by local law and subject to applicable exceptions, individuals may have the following privacy rights in relation to their Personal Information: <br />
The right to know information about our processing of your Personal Information, including the specific pieces of Personal Information that we have collected from you; <br />
The right to request deletion of your Personal Information; <br />
The right to correct your Personal Information; and<br />
The right to be free from discrimination relating to the exercise of any of your privacy rights. <br />
We don’t “sell” Personal Information or “share” Personal Information for cross-contextual behavioral advertising (as those terms are defined under applicable local law). We also don’t process sensitive Personal Information for the purposes of inferring characteristics about a consumer. <br />
Verification. In order to protect your Personal Information from unauthorized access, change, or deletion, we may require you to verify your credentials before you can submit a request to know, correct, or delete Personal Information. If you do not have an account with us, or if we suspect fraudulent or malicious activity, we may ask you to provide additional Personal Information and proof of residency for verification. If we cannot verify your identity, we will not be able to honor your request. <br />
Authorized Agents. You may also submit a rights request through an authorized agent. If you do so, the agent must present signed written permission to act on your behalf and you may also be required to independently verify your identity and submit proof of your residency with us. Authorized agent requests can be submitted to . <br />
Appeals. Depending on where you live, you may have the right to appeal a decision we make relating to requests to exercise your rights under applicable local law. To appeal a decision, please send your request to . <br />
6. Children<br />
Our Service is not directed to children under the age of 13. Finsight Anlaytics does not knowingly collect Personal Information from children under the age of 13. If you have reason to believe that a child under the age of 13 has provided Personal Information to Finsight Anlaytics through the Service, please email us at legal@Finsight Anlaytics.com. We will investigate any notification and if appropriate, delete the Personal Information from our systems. If you are 13 or older, but under 18, you must have permission from your parent or guardian to use our Services. <br />
7. Links to other websites<br />
The Service may contain links to other websites not operated or controlled by Finsight Anlaytics, including social media services (“Third Party Sites”). The information that you share with Third Party Sites will be governed by the specific privacy policies and terms of service of the Third Party Sites and not by this Privacy Policy. By providing these links we do not imply that we endorse or have reviewed these sites. Please contact the Third Party Sites directly for information on their privacy practices and policies. <br />
8. Security and Retention<br />
We implement commercially reasonable technical, administrative, and organizational measures to protect Personal Information both online and offline from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or email transmission is ever fully secure or error free. In particular, email sent to or from us may not be secure. Therefore, you should take special care in deciding what information you send to us via the Service or email. In addition, we are not responsible for circumvention of any privacy settings or security measures contained on the Service, or third-party websites. <br />
We’ll retain your Personal Information for only as long as we need in order to provide our Service to you, or for other legitimate business purposes such as resolving disputes, safety and security reasons, or complying with our legal obligations. How long we retain Personal Information will depend on a number of factors, such as the amount, nature, and sensitivity of the information, the potential risk of harm from unauthorized use or disclosure, our purpose for processing the information, and any legal requirements. <br />
9. International users<br />
By using our Service, you understand and acknowledge that your Personal Information will be processed and stored in our facilities and servers in the United States and may be disclosed to our service providers and affiliates in other jurisdictions. <br />
Legal Basis for Processing. Our legal bases for processing your Personal Information include: <br />
Performance of a contract with you when we provide and maintain our Services. When we process Account Information, Content, and Technical Information solely to provide our Services to you, this information is necessary to be able to provide our Services. If you do not provide this information, we may not be able to provide our Services to you. <br />
Our legitimate interests in protecting our Services from abuse, fraud, or security risks, or in developing, improving, or promoting our Services, including when we train our models. This may include the processing of Account Information, Content, Social Information, and Technical Information. Read  on how you can opt out of our use of your information to train our models. <br />
Your consent when we ask for your consent to process your Personal Information for a specific purpose that we communicate to you. You have the right to withdraw your consent at any time. <br />
Compliance with our legal obligations when we use your Personal Information to comply with applicable law or when we protect our or our affiliates’, users’, or third parties’ rights, safety, and property. <br />
Data Transfers. Where required, we will use appropriate safeguards for transferring Personal Information outside of certain countries. We will only transfer Personal Information pursuant to a legally valid transfer mechanism. <br />
10. Changes to the privacy policy<br />
We may update this Privacy Policy from time to time. When we do, we will post an updated version on this page, unless another type of notice is required by applicable law. <br />
11. How to contact us<br />
Please support @finsightanalytics.org if you have any questions or concerns not already addressed in this Privacy Policy. <br />

          </p>
    </div>
  )
}
