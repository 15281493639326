// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
}

.terms-modal {
  width: 60%;
  height: 80%; 
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden; 
}

.terms-header {
  padding: 20px; 
  text-align: center;
  background-color: #f5f5f5; 
}

.terms-content {
  flex-grow: 1; 
  overflow-y: auto; 
  padding: 20px; 
  word-wrap: break-word; 
}

.terms-modal-actions {
  padding: 10px; 
  display: flex;
  justify-content: space-around; 
  background-color: #f5f5f5;
}

.accept-button,
.close-button {
  width: 125px;
  padding: 10px 20px;
  border-radius: 20px ; 
  cursor: pointer;
  
}

.accept-button {
  background-color: #673AB7; 
  color: white;
 
}

.accept-button.disabled {
  background-color: #ccc; 
  cursor: not-allowed; 
}

.close-button {
  background-color: #f44336; /* Red for the Close button */
  color: white;
}

.accept-button:hover:not(.disabled),
.close-button:hover {
  opacity: 0.8; 
}
`, "",{"version":3,"sources":["webpack://./src/termsandconditions.css"],"names":[],"mappings":";;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,uBAAuB;EACvB,wCAAwC;EACxC,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,6BAA6B;EAC7B,yBAAyB;AAC3B;;AAEA;;EAEE,YAAY;EACZ,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;;AAEjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;;AAEd;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB,EAAE,6BAA6B;EACxD,YAAY;AACd;;AAEA;;EAEE,YAAY;AACd","sourcesContent":["\n\n.modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5); \n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.terms-modal {\n  width: 60%;\n  height: 80%; \n  background-color: white;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden; \n}\n\n.terms-header {\n  padding: 20px; \n  text-align: center;\n  background-color: #f5f5f5; \n}\n\n.terms-content {\n  flex-grow: 1; \n  overflow-y: auto; \n  padding: 20px; \n  word-wrap: break-word; \n}\n\n.terms-modal-actions {\n  padding: 10px; \n  display: flex;\n  justify-content: space-around; \n  background-color: #f5f5f5;\n}\n\n.accept-button,\n.close-button {\n  width: 125px;\n  padding: 10px 20px;\n  border-radius: 20px ; \n  cursor: pointer;\n  \n}\n\n.accept-button {\n  background-color: #673AB7; \n  color: white;\n \n}\n\n.accept-button.disabled {\n  background-color: #ccc; \n  cursor: not-allowed; \n}\n\n.close-button {\n  background-color: #f44336; /* Red for the Close button */\n  color: white;\n}\n\n.accept-button:hover:not(.disabled),\n.close-button:hover {\n  opacity: 0.8; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
