// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.rectangle {
  width: 20px;
  height: 80px;
  background-color: #673AB7;
  margin: 0 10px;
  animation: loadingAnimation 2s infinite;
  animation-delay: 0.2s; /* Initial delay for the first rectangle */
}

.rectangle:nth-child(2) {
  animation-delay: 0.4s; 
}

.rectangle:nth-child(3) {
  animation-delay: 0.6s; 
}

@keyframes loadingAnimation {
  0% {
    transform: scaleY(0.5);
  }
  25% {
    transform: scaleY(1.0);
  }
  50% {
    transform: scaleY(1.5);
  }
  75% {
    transform: scaleY(1.0);
  }
  100% {
    transform: scaleY(0.5);
  }
}
`, "",{"version":3,"sources":["webpack://./src/NewTabLoader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,cAAc;EACd,uCAAuC;EACvC,qBAAqB,EAAE,0CAA0C;AACnE;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".loader-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  width: 100%;\n}\n\n.rectangle {\n  width: 20px;\n  height: 80px;\n  background-color: #673AB7;\n  margin: 0 10px;\n  animation: loadingAnimation 2s infinite;\n  animation-delay: 0.2s; /* Initial delay for the first rectangle */\n}\n\n.rectangle:nth-child(2) {\n  animation-delay: 0.4s; \n}\n\n.rectangle:nth-child(3) {\n  animation-delay: 0.6s; \n}\n\n@keyframes loadingAnimation {\n  0% {\n    transform: scaleY(0.5);\n  }\n  25% {\n    transform: scaleY(1.0);\n  }\n  50% {\n    transform: scaleY(1.5);\n  }\n  75% {\n    transform: scaleY(1.0);\n  }\n  100% {\n    transform: scaleY(0.5);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
