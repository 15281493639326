// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    width: 75vw;
    height: 100vh;
    margin-left: 25%;
}

.box1 {
    background-color: rgb(235, 234, 234);
    width: 50vw;
    height: 7vh;
    margin-left: 17%;
    margin-top: 6%;
    border-radius: 12px;
}

.box2 {
    background-color: rgb(235, 234, 234);
    width: 30vw;
    height: 7vh;
    margin-left: 17%;
    margin-top: 2.5%;
    border-radius: 12px;
}

.box3 {
    background-color: rgb(235, 234, 234);
    width: 40vw;
    height: 7vh;
    margin-left: 17%;
    margin-top: 2.5%;
    border-radius: 12px;
}

.box4 {
    background-color: rgb(235, 234, 234);
    width: 55vw;
    height: 7vh;
    margin-left: 17%;
    margin-top: 2.5%;
    border-radius: 12px;
}

.box5 {
    background-color: rgb(235, 234, 234);
    width: 48vw;
    height: 7vh;
    margin-left: 17%;
    margin-top: 2.5%;
    border-radius: 12px;
}

.box6 {
    background-color: rgb(235, 234, 234);
    width: 8vw;
    height: 7vh;
    margin-left: 17%;
    margin-top: 2.5%;
    border-radius: 12px;
}

.box7 {
    background-color: rgb(235, 234, 234);
    width: 35vw;
    height: 7vh;
    margin-left: 17%;
    margin-top: 2.5%;
    border-radius: 12px;
}`, "",{"version":3,"sources":["webpack://./src/loader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;IACpC,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;IACpC,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;IACpC,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;IACpC,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;IACpC,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;IACpC,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;IACpC,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".loader {\n    width: 75vw;\n    height: 100vh;\n    margin-left: 25%;\n}\n\n.box1 {\n    background-color: rgb(235, 234, 234);\n    width: 50vw;\n    height: 7vh;\n    margin-left: 17%;\n    margin-top: 6%;\n    border-radius: 12px;\n}\n\n.box2 {\n    background-color: rgb(235, 234, 234);\n    width: 30vw;\n    height: 7vh;\n    margin-left: 17%;\n    margin-top: 2.5%;\n    border-radius: 12px;\n}\n\n.box3 {\n    background-color: rgb(235, 234, 234);\n    width: 40vw;\n    height: 7vh;\n    margin-left: 17%;\n    margin-top: 2.5%;\n    border-radius: 12px;\n}\n\n.box4 {\n    background-color: rgb(235, 234, 234);\n    width: 55vw;\n    height: 7vh;\n    margin-left: 17%;\n    margin-top: 2.5%;\n    border-radius: 12px;\n}\n\n.box5 {\n    background-color: rgb(235, 234, 234);\n    width: 48vw;\n    height: 7vh;\n    margin-left: 17%;\n    margin-top: 2.5%;\n    border-radius: 12px;\n}\n\n.box6 {\n    background-color: rgb(235, 234, 234);\n    width: 8vw;\n    height: 7vh;\n    margin-left: 17%;\n    margin-top: 2.5%;\n    border-radius: 12px;\n}\n\n.box7 {\n    background-color: rgb(235, 234, 234);\n    width: 35vw;\n    height: 7vh;\n    margin-left: 17%;\n    margin-top: 2.5%;\n    border-radius: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
