// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CSS: uploadcss */
/* .selectFileLoader {
    height: 4px;
    width: 130px;
    --c:no-repeat linear-gradient(#6100ee 0 0);
    background: var(--c),var(--c),#d7b8fc;
    background-size: 60% 100%;
    animation: l16 3s infinite;
  }
  @keyframes l16 {
    0%   {background-position:-150% 0,-150% 0}
    66%  {background-position: 250% 0,-150% 0}
    100% {background-position: 250% 0, 250% 0}
  } */
  /* HTML: <div class="loader"></div> */
.selectFileLoader {
  width: 120px;
  height: 22px;
  border-radius: 20px;
  color: #514b82;
  border: 2px solid;
  position: relative;
}
.selectFileLoader::before {
  content: "";
  position: absolute;
  margin: 2px;
  inset: 0 100% 0 0;
  border-radius: inherit;
  background: currentColor;
  animation: l6 2s infinite;
}
@keyframes l6 {
    100% {inset:0}
}`, "",{"version":3,"sources":["webpack://./src/component/helpers/selectFileLoader.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;;;;;;;;;;;;KAYK;EACH,qCAAqC;AACvC;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,sBAAsB;EACtB,wBAAwB;EACxB,yBAAyB;AAC3B;AACA;IACI,MAAM,OAAO;AACjB","sourcesContent":["/* CSS: uploadcss */\n/* .selectFileLoader {\n    height: 4px;\n    width: 130px;\n    --c:no-repeat linear-gradient(#6100ee 0 0);\n    background: var(--c),var(--c),#d7b8fc;\n    background-size: 60% 100%;\n    animation: l16 3s infinite;\n  }\n  @keyframes l16 {\n    0%   {background-position:-150% 0,-150% 0}\n    66%  {background-position: 250% 0,-150% 0}\n    100% {background-position: 250% 0, 250% 0}\n  } */\n  /* HTML: <div class=\"loader\"></div> */\n.selectFileLoader {\n  width: 120px;\n  height: 22px;\n  border-radius: 20px;\n  color: #514b82;\n  border: 2px solid;\n  position: relative;\n}\n.selectFileLoader::before {\n  content: \"\";\n  position: absolute;\n  margin: 2px;\n  inset: 0 100% 0 0;\n  border-radius: inherit;\n  background: currentColor;\n  animation: l6 2s infinite;\n}\n@keyframes l6 {\n    100% {inset:0}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
