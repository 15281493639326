// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./img/logo.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcomepage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 14%;
    margin-top: 10%;
    flex-direction: column;
}

.welcome-text {
    font-size: 30px;
}

.welcome-logo {
    height: 150px;
    width: 150px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: cover;
}

.icons-container {
    width: 75%;
    margin-left: 13%;
}

.excel-icon,
.file-icon,
.computer-icon,
.folder-open-icon {
    width: 60px;
    height: 60px;
    color: #2082c4;
    margin: 4%;
    opacity: 25%;
}`, "",{"version":3,"sources":["webpack://./src/welcome.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,yDAAuC;IACvC,4BAA4B;IAC5B,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;;;;IAII,WAAW;IACX,YAAY;IACZ,cAAc;IACd,UAAU;IACV,YAAY;AAChB","sourcesContent":[".welcomepage {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-left: 14%;\n    margin-top: 10%;\n    flex-direction: column;\n}\n\n.welcome-text {\n    font-size: 30px;\n}\n\n.welcome-logo {\n    height: 150px;\n    width: 150px;\n    background-image: url('./img/logo.png');\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n.icons-container {\n    width: 75%;\n    margin-left: 13%;\n}\n\n.excel-icon,\n.file-icon,\n.computer-icon,\n.folder-open-icon {\n    width: 60px;\n    height: 60px;\n    color: #2082c4;\n    margin: 4%;\n    opacity: 25%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
