// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.interaction-home-div{
    overflow-y: auto;
    background-color: #e2e0e0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    gap: 5%;
}

.interaction-type{
    margin-top: 5%;
    margin-bottom: 5%;
    position: relative;
    width: 17%;
    height: 42vh;
    background-color: #fbfbfb;
    border-radius: 8px;

    h3{
        padding-left: 10%;
        padding-top: 12%;
    }

    p{
        padding-left: 10%;
        padding-right: 8%;
        margin-top: 7%;

    }
}

.interaction-btn{
    position: absolute;
    margin-left: 10%;
    bottom: 20%;
    width: 60%;
    height: 40px;
    border: 2px solid #d7d7d7;
    background-color:#fbfbfb;
    border-radius: 7px;
    color: #673ab7;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s; 
    
}
.interaction-btn:hover {
    background-color: #673ab7; 
    color: #ffffff;
    border: 2px solid #673ab7;
}

@media screen and (max-width: 768px){
    

    .interaction-home-div{
        flex-direction: column;
    }
    

    .interaction-type{
        width: 70%;
        height: 80%;
            }
}`, "",{"version":3,"sources":["webpack://./src/selectinteraction.css"],"names":[],"mappings":";;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,4BAA4B;IAC5B,OAAO;AACX;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;;IAElB;QACI,iBAAiB;QACjB,gBAAgB;IACpB;;IAEA;QACI,iBAAiB;QACjB,iBAAiB;QACjB,cAAc;;IAElB;AACJ;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,wBAAwB;IACxB,kBAAkB;IAClB,cAAc;IACd,iBAAiB;IACjB,6CAA6C;;AAEjD;AACA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;AAC7B;;AAEA;;;IAGI;QACI,sBAAsB;IAC1B;;;IAGA;QACI,UAAU;QACV,WAAW;YACP;AACZ","sourcesContent":["\n\n.interaction-home-div{\n    overflow-y: auto;\n    background-color: #e2e0e0;\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    /* flex-direction: column; */\n    gap: 5%;\n}\n\n.interaction-type{\n    margin-top: 5%;\n    margin-bottom: 5%;\n    position: relative;\n    width: 17%;\n    height: 42vh;\n    background-color: #fbfbfb;\n    border-radius: 8px;\n\n    h3{\n        padding-left: 10%;\n        padding-top: 12%;\n    }\n\n    p{\n        padding-left: 10%;\n        padding-right: 8%;\n        margin-top: 7%;\n\n    }\n}\n\n.interaction-btn{\n    position: absolute;\n    margin-left: 10%;\n    bottom: 20%;\n    width: 60%;\n    height: 40px;\n    border: 2px solid #d7d7d7;\n    background-color:#fbfbfb;\n    border-radius: 7px;\n    color: #673ab7;\n    font-weight: bold;\n    transition: background-color 0.3s, color 0.3s; \n    \n}\n.interaction-btn:hover {\n    background-color: #673ab7; \n    color: #ffffff;\n    border: 2px solid #673ab7;\n}\n\n@media screen and (max-width: 768px){\n    \n\n    .interaction-home-div{\n        flex-direction: column;\n    }\n    \n\n    .interaction-type{\n        width: 70%;\n        height: 80%;\n            }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
