// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-loader{
position: absolute;
top: 14%;
left: 43%;
width: 30px;
padding: 8px;
/* margin-left: 96px; */
aspect-ratio: 1;
border-radius: 50%;
background: #e6e2eb;
--_m: conic-gradient(#0000 10%,#000), linear-gradient(#000 0 0) content-box;
mask: var(--_m);
mask-composite: subtract;
animation: l3 1s infinite linear;
}`, "",{"version":3,"sources":["webpack://./src/buttonLoader.css"],"names":[],"mappings":"AAAA;AACA,kBAAkB;AAClB,QAAQ;AACR,SAAS;AACT,WAAW;AACX,YAAY;AACZ,uBAAuB;AACvB,eAAe;AACf,kBAAkB;AAClB,mBAAmB;AACnB,2EAA2E;AAC3E,eAAe;AACf,wBAAwB;AACxB,gCAAgC;AAChC","sourcesContent":[".button-loader{\nposition: absolute;\ntop: 14%;\nleft: 43%;\nwidth: 30px;\npadding: 8px;\n/* margin-left: 96px; */\naspect-ratio: 1;\nborder-radius: 50%;\nbackground: #e6e2eb;\n--_m: conic-gradient(#0000 10%,#000), linear-gradient(#000 0 0) content-box;\nmask: var(--_m);\nmask-composite: subtract;\nanimation: l3 1s infinite linear;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
