// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-loader  {
    width: 46px;
    height: 13px;
    aspect-ratio: 4;
    --_g: no-repeat radial-gradient(circle closest-side,#000 90%,#0000);
    background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
    background-size: calc(100% / 3) 100%;
    animation: l7 1s infinite linear;
}
@keyframes l7 {
    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
}
  `, "",{"version":3,"sources":["webpack://./src/component/helpers/chatLoader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,mEAAmE;IACnE,mEAAmE;IACnE,oCAAoC;IACpC,gCAAgC;AACpC;AACA;IACI,IAAI,qEAAqE;IACzE,IAAI,qEAAqE;IACzE,IAAI,qEAAqE;AAC7E","sourcesContent":[".chat-loader  {\n    width: 46px;\n    height: 13px;\n    aspect-ratio: 4;\n    --_g: no-repeat radial-gradient(circle closest-side,#000 90%,#0000);\n    background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;\n    background-size: calc(100% / 3) 100%;\n    animation: l7 1s infinite linear;\n}\n@keyframes l7 {\n    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}\n    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}\n    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
