// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.send-icon:active {
    color: black;
}
/*  */


`, "",{"version":3,"sources":["webpack://./src/chatbox.css"],"names":[],"mappings":";;AAEA;IACI,YAAY;AAChB;AACA,KAAK","sourcesContent":["\n\n.send-icon:active {\n    color: black;\n}\n/*  */\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
